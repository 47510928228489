import _react2 from "react";
import _debounce2 from "@react-hook/debounce";
var exports = {};
exports.__esModule = true;
exports.default = exports.useWindowWidth = exports.useWindowHeight = exports.useWindowSize = void 0;

var _react = _interopRequireDefault(_react2);

var _debounce = _interopRequireDefault(_debounce2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

const {
  useEffect
} = _react.default;
const emptyArr = [];
const emptyObj = {};

const useWindowSize = (initialWidth, initialHeight, options = emptyObj) => {
  const {
    wait,
    leading
  } = options;
  const [size, setDebouncedSize] = (0, _debounce.default)(
  /* istanbul ignore next */
  typeof document === "undefined" ? [initialWidth, initialHeight] : [document.documentElement.clientWidth, document.documentElement.clientHeight], wait, leading);

  function _ref() {
    return setDebouncedSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);
  }

  useEffect(() => {
    const setSize = _ref;
    window.addEventListener("resize", setSize);
    window.addEventListener("orientationchange", setSize);
    return () => {
      window.removeEventListener("resize", setSize);
      window.removeEventListener("orientationchange", setSize);
    };
  }, emptyArr);
  return size;
};

exports.useWindowSize = useWindowSize;

const useWindowHeight = (initialValue = 0, options) => useWindowSize(0, initialValue, options)[1];

exports.useWindowHeight = useWindowHeight;

const useWindowWidth = (initialValue = 0, options) => useWindowSize(initialValue, 0, options)[0];

exports.useWindowWidth = useWindowWidth;
var _default = useWindowSize;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;
const _useWindowWidth = exports.useWindowWidth,
      _useWindowHeight = exports.useWindowHeight,
      _useWindowSize = exports.useWindowSize;
export { _useWindowWidth as useWindowWidth, _useWindowHeight as useWindowHeight, _useWindowSize as useWindowSize };